<template>
  <div>
    <invoice-plan-card />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>عرض</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>مدخلات</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="بحث..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refInvoiceListTable"
        class="position-relative"
        :items="fetchInvoices"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="لم يتم العثور على سجلات مطابقة"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: type text -->
        <template #cell(reference)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: type text -->
        <template #cell(total)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: type text -->
        <template #cell(starts_at)="data">
          <span class="text-nowrap">
            {{ formatDate(data.value) }}
          </span>
        </template>
        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: type text -->
        <template #cell(ends_at)="data">
          <span class="text-nowrap">
            {{ formatDate(data.value) }}
          </span>
        </template>
        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: type text -->
        <template #cell(payment_status)="data">
          <span class="text-nowrap">
            {{ $t(data.value.toLowerCase()) }}
          </span>
        </template>
        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: type text -->
        <template #cell(payment_method)="data">
          <span class="text-nowrap">
            {{ $t(data.value.toLowerCase()) }}
          </span>
        </template>
        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: type text -->
        <template #cell(paid_at)="data">
          <span class="text-nowrap">
            {{ formatDate(data.value) }}
          </span>
        </template>
        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: type text -->
        <template #cell(transaction_number)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="download(data.item)">
              <feather-icon icon="FileIcon" />
              <span class="align-middle ml-50">تنزيل</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">عرض من {{ dataMeta.from }} إلى {{ dataMeta.to }} من {{ dataMeta.of }} مدخلات</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalInvoice"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import InvoiceListFilters from './InvoiceListFilters.vue';
import useInvoiceList from './useInvoiceList';
import invoiceStore from '../invoiceStore';
import InvoiceListAddNew from './InvoiceListAddNew.vue';
import InvoicePlanCard from './InvoicePlanCard.vue';
import moment from 'moment';

export default {
  components: {
    InvoicePlanCard,
    InvoiceListFilters,
    InvoiceListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    formatDate(date) {
      if (moment(date, 'Y-M-D H:i:s').isValid()) {
        // Format the valid date
        return moment(date, 'Y-M-D H:i:s').format('YYYY-MM-DD ');
      } else {
        // Return an empty string for invalid dates
        return '';
      }
     },
    download(invoice) {
      this.$toast.info('جاري التنزيل...', {
        autoHide: false,
        appendToast: ToastificationContent,
      });
      store.dispatch('invoice/downloadInvoice', { id: invoice.id })
        .then((response) => {
          this.downloadFile(response.data, invoice.reference);
          this.$toast.success('تم التنزيل بنجاح');
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error('حدث خطأ اثناء التنزيل');
        });
    },
    downloadFile(data, filename, type = 'application/pdf') {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `${filename}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
    },
    deleteInvoice(invoice) {
      store.dispatch('invoice/deleteInvoice', { id: invoice.id })
        .then((response) => {
          this.$emit('refetch-data');
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم الحذف بنجاح',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            invoiceData.value = undefined;
          }
        });
    },
    confirmDelete(invoice) {
      this.$bvModal
        .msgBoxConfirm(`يرجى تأكيد أنك تريد حذف العنصر  ${invoice.id}.`, {
          title: 'يرجى التأكيد',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'نعم',
          cancelTitle: 'لا',
          cancelVariant: 'outline-warning',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteInvoice(invoice);
          }
        });
    },

  },
  setup() {
    const INVOICE_STORE_MODULE_NAME = 'invoice';

    // Register invoice
    if (!store.hasModule(INVOICE_STORE_MODULE_NAME)) store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_STORE_MODULE_NAME);
    });

    const isAddNewInvoiceSidebarActive = ref(false);
    const payment_statusOptions = [
      {
        label: 'لم يتم السداد',
        value: 'unpaid',
      },
      {
        label: 'تم السداد',
        value: 'paid',
      },
    ];
    const payment_methodOptions = [
      {
        label: 'كاش',
        value: 'cash',
      },
      {
        label: 'تحويل بنكي',
        value: 'transfer',
      },
    ];

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoice,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      refetchData,

      // UI
      payment_statusFilter,
      payment_methodFilter,
    } = useInvoiceList();

    return {

      // Sidebar
      isAddNewInvoiceSidebarActive,

      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoice,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      payment_statusOptions,
      payment_statusFilter,
      payment_methodOptions,
      payment_methodFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
